import React, { useState, useEffect, useRef } from 'react';
import { createProducts } from '../homeApi';
import './CreateProductModal.css';

interface CreateProductModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateProductModal: React.FC<CreateProductModalProps> = ({ isOpen, onClose }) => {
  const [nomeProduto, setNomeProduto] = useState('');
  const [sap, setSap] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const modalRef = useRef<HTMLDivElement>(null);

  const handleCreateProduct = async () => {
    setIsLoading(true);
    setError(null);

    try {
      await createProducts([{ nomeProduto, sap }]);
      onClose();
      window.alert('Produto cadastrado com sucesso!');
    } catch (err) {
      setError('Erro ao cadastrar produto. Tente novamente.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        <div>
          <div className="CadastrarProduto">Cadastrar Produto</div>
        </div>

        <label className="OpcoesProduto">
          Nome do Produto:
          <input
            type="text"
            value={nomeProduto}
            onChange={(e) => setNomeProduto(e.target.value)}
          />
        </label>

        <label className="OpcoesProduto">
          SAP:
          <input
            type="text"
            value={sap}
            onChange={(e) => setSap(e.target.value)}
          />
        </label>

        {error && <p style={{ color: 'red' }}>{error}</p>}

        <div className="modal-actions">
          <div className="CtaBig Cancel" onClick={onClose}>
            <div className="ComponentName">Cancelar</div>
          </div>
          <div
            className={`CtaBig Cadastro ${
              isLoading || !nomeProduto || !sap ? 'disabled' : ''
            }`}
            onClick={
              isLoading || !nomeProduto || !sap ? undefined : handleCreateProduct
            }
          >
            <div className={`ButtonText ${isLoading || !nomeProduto || !sap ? 'disabled' : ''}`}>
              {isLoading ? 'Cadastrando...' : 'Cadastrar'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProductModal;

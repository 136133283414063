import React, { useState, useEffect, useRef } from 'react';
import { createStore } from '../homeApi';
import './CreateStoreModal.css';

interface CreateStoreModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateStoreModal: React.FC<CreateStoreModalProps> = ({ isOpen, onClose }) => {
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [endereco, setEndereco] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const modalRef = useRef<HTMLDivElement>(null);

  const handleCreateStore = async () => {
    setIsLoading(true);
    setError(null);

    try {
      await createStore({ nomeFantasia, endereco });
      window.alert('Loja cadastrada com sucesso!');
      onClose();
    } catch (err) {
      setError('Erro ao criar loja. Tente novamente.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        <div>
          <div className="CadastrarLoja">Cadastrar Loja</div>
        </div>

      <label className="OpcoesProduto">
        Darkstore:
        <input
          type="text"
          value={nomeFantasia}
          onChange={(e) => setNomeFantasia(e.target.value)}
        />
      </label>

      <label className="OpcoesProduto">
        Endereço:
        <input
          type="text"
          value={endereco}
          onChange={(e) => setEndereco(e.target.value)}
        />
      </label>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      <div className="modal-actions">
        <div className="CtaBig Cancel" onClick={onClose}>
          <div className="ComponentName">Cancelar</div>
        </div>
        <div
          className={`CtaBig Cadastro ${
            isLoading || !nomeFantasia || !endereco ? 'disabled' : ''
          }`}
          onClick={
            isLoading || !nomeFantasia || !endereco ? undefined : handleCreateStore
          }
        >
          <div className={`ButtonText ${isLoading || !nomeFantasia || !endereco ? 'disabled' : ''}`}>
            {isLoading ? 'Cadastrando...' : 'Cadastrar'}
          </div>
        </div>
      </div>
    </div>
  </div>
);
};

export default CreateStoreModal;

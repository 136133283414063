import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './views/home/pages/homepage';
import { HOMEPAGE } from './views/home/routes';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path={HOMEPAGE()} element={<HomePage />} />

        <Route path="*" element={<Navigate to={HOMEPAGE()} />} />
      </Routes>
    </Router>
  );
};

export default App;
